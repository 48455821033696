import { ITask } from '@@types/types';
import { extraLightHapticFeedback } from '@common/telegramHaptic';
import { useStore } from '@hooks/storeHook';

interface IBoardSelector {
  task: ITask;
  setTask: (field: string, value: ITask[keyof ITask]) => void;
  onHide: () => void;
}

export const BoardSelector = ({ task, onHide, setTask }: IBoardSelector) => {
  const { activeDesks: activeDesks } = useStore();

  const onSelectDesk = (value: string) => {
    const desk = activeDesks.find((item) => item.name === value);
    if (desk) setTask('desk', desk);
    extraLightHapticFeedback();
    onHide();
  };

  return activeDesks
    ?.filter((desk) => desk.id !== task.desk?.id)
    .map((desk) => (
      <div
        onClick={() => onSelectDesk(desk.name)}
        className='w-full flex items-center gap-[5px] border-solid  border-0 border-border-grey border-b-[1px] py-[10px] cursor-pointer'
        key={desk.id}
      >
        <text className='text-large font-medium'>{desk.name}</text>
        <text className='text-large text-blue ml-auto'>Выбрать</text>
      </div>
    ));
};
