import { useDroppable } from '@dnd-kit/core';
import { SortableContext, SortingStrategy } from '@dnd-kit/sortable';
import { areEqualWithCtx } from '@shared/lib/dnd';
import { DragContext, Nestable, NestableProps } from '@shared/types/dnd.types';
import cn from 'classnames';
import React from 'react';

function useClasses({
  isDroppable,
  className,
  isDropzoneHovered,
}: {
  isDroppable: boolean;
  className?: string;
  isDropzoneHovered?: boolean;
}) {
  return cn(className, {
    'is-droppable': isDroppable,
    'is-active-dropzone': isDropzoneHovered,
  });
}

export interface SortableListProps<ListType, ChildType> {
  accepts: string;
  content: Nestable<ListType, ChildType>;
  ctx: DragContext<ListType>;
  className: string;
  direction: SortingStrategy;
}

export const SortableList = React.memo(function SortableList<
  ListType,
  ChildType extends NestableProps,
  direction,
>({
  children,
  content,
  className,
  direction,
}: React.PropsWithChildren<SortableListProps<ListType, ChildType>>) {
  return (
    <SortableContext
      id={`sortable-${content.id}`}
      items={content.children}
      strategy={direction}
    >
      <div className={useClasses({ isDroppable: false, className })}>
        {children}
      </div>
    </SortableContext>
  );
}, areEqualWithCtx);

export const SortableDroppableList = React.memo(function SortableDroppableList<
  ListType,
  ChildType extends NestableProps,
>({
  accepts,
  content,
  children,
  className,
  ctx,
}: React.PropsWithChildren<SortableListProps<ListType, ChildType>>) {
  const sortableId = `sortable-${content.id}`;
  const { active, over, isOver, setNodeRef } = useDroppable({
    id: `droppable-${content.id}`,
    data: ctx,
  });

  const isDropzoneHovered =
    active?.data.current?.data.type === accepts &&
    (isOver || over?.data.current?.sortable?.containerId === sortableId);

  return (
    <SortableContext id={sortableId} items={content.children}>
      <div
        ref={setNodeRef}
        className={useClasses({
          isDroppable: true,
          className,
          isDropzoneHovered,
        })}
      >
        {children}
      </div>
    </SortableContext>
  );
}, areEqualWithCtx);

export const SortableListOverlay = React.memo(function SortableListOverlay<
  ListType,
  ChildType extends NestableProps,
>({
  children,
  className,
}: React.PropsWithChildren<SortableListProps<ListType, ChildType>>) {
  return (
    <div className={useClasses({ isDroppable: false, className })}>
      {children}
    </div>
  );
}, areEqualWithCtx);
