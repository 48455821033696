import { observer } from 'mobx-react-lite';
import { TaskInfoProps } from '../types';

export const TaskInfo = observer(
  ({ task, extended = false, isInline }: TaskInfoProps) => {
    return (
      <>
        <span className='text-blue'>#{task.number} </span>
        {task.title}
        {task.subtasks.length !== 0 && (
          <div className='min-w-[39px] h-[21px] bg-subTasksBackground rounded-[4px] inline-flex justify-center items-center text-white text-base mx-[5px]'>
            {task.subtasks.reduce(
              (prev, cur) => (cur.status == 'complete' ? prev + 1 : prev),
              0,
            )}{' '}
            / {task.subtasks.length}
          </div>
        )}
        {Array.isArray(task.responsible) &&
          task.responsible.length > 0 &&
          task.responsible[0] &&
          isInline &&
          !extended &&
          (task.responsible[0].photo_url ? (
            <img
              src={task.responsible[0].photo_url}
              className='object-contain rounded-[100px] w-[21px] h-[21px] bg-grey inline mx-[5px] align-middle'
            />
          ) : (
            <div className='min-w-[21px] min-h-[21px] text-small font-bold text-white rounded-[100px] bg-blue inline-flex justify-center items-center mx-[5px] align-middle'>
              {(task.responsible[0].last_name?.[0]?.toUpperCase() ?? '') +
                (task.responsible[0].first_name?.[0]?.toUpperCase() ?? '')}
            </div>
          ))}
      </>
    );
  },
);
