import { LoadState } from '@@types/loadState';
import Background from '@assets/background.webp';
import { RootLoader } from '@common/RootLoader/RootLoader';
import { ScrollToTop } from '@common/scrollToTop';
import { AppRoutes, AppRoutesPaths, AppRoutesType } from '@config/navigation';
import { withConfigs } from '@config/withConfigs';
import { useLogic, useStore } from '@hooks/storeHook';
import { TaskModalController } from '@ui/TaskModalController/TaskModalController';
import { getQueryParams } from '@utils/getQueryParams';
import { AnimatePresence } from 'framer-motion';
import { useEffect } from 'react';
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { YMInitializer } from 'react-yandex-metrika';
import './App.css';
import './common/commonStyles.css';
import './common/telegramTheme.css';

export const App = withConfigs(() => {
  const location = useLocation();
  const logic = useLogic();
  const navigate = useNavigate();
  const tg = window.Telegram.WebApp;
  const { setChatId, setUserId, setRootLoading } = useStore();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const { chatId, taskId } = getQueryParams(
      tg.initDataUnsafe?.start_param ?? searchParams.get('chatId'),
    );

    const userId = tg.initDataUnsafe?.user?.id;

    if (chatId) {
      setChatId(+chatId);
    } else if (userId && !chatId) {
      setChatId(0);
    }

    const decodedData = encodeURIComponent(
      decodeURIComponent(
        decodeURIComponent(
          decodeURIComponent(window.location.hash.replace('#', '')),
        ),
      ),
    )
      .replaceAll('%3D', '=')
      .replaceAll('%26', '&');

    const initData = tg.initData.length > 0 ? tg.initData : decodedData;

    if (!initData) {
      setRootLoading(LoadState.Error);
      return;
    }
    logic.initializeApiLogic(initData);

    if (userId) {
      setUserId(userId);
    }

    const init = async () => {
      await logic.loadInitialData();
      if (chatId && taskId) await logic.getTask(+chatId, taskId);
    };

    init();

    if (chatId == 0 || (userId && !chatId)) {
      navigate(AppRoutesPaths.AllTasksAndChats);
    }
  }, []);

  const renderRoutes = (routes?: AppRoutesType) => {
    if (!routes) {
      return null;
    }
    return routes.map((route) => (
      <Route key={route.name} element={route.element} path={route.path}>
        {renderRoutes(route.childs)}
      </Route>
    ));
  };

  return (
    <ScrollToTop>
      <div
        style={{
          backgroundImage: `url(${Background})`,
          backgroundSize: 'cover',
        }}
        className='w-screen h-[100svh]'
      >
        <RootLoader>
          <TaskModalController>
            <AnimatePresence initial={false} mode='wait'>
              <Routes location={location} key={location.key}>
                {renderRoutes(AppRoutes)}
              </Routes>
            </AnimatePresence>
          </TaskModalController>
        </RootLoader>
      </div>
      <YMInitializer
        accounts={[97905569]}
        options={{
          clickmap: true,
          trackLinks: true,
          accurateTrackBounce: true,
        }}
      />
    </ScrollToTop>
  );
});
