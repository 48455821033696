import { SvgIconProps } from '@shared/types/icon.types';

export const RedirectIcon = ({
  width = 16,
  height = 16,
  fill = '#FFF',
}: SvgIconProps) => {
  return (
    <svg
      width={width.toString()}
      height={height.toString()}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M9.16667 6.83333L15 1M15 1H11.1111M15 1V4.88889M15 9.55556V13.4444C15 13.857 14.8361 14.2527 14.5444 14.5444C14.2527 14.8361 13.857 15 13.4444 15H2.55556C2.143 15 1.74733 14.8361 1.45561 14.5444C1.16389 14.2527 1 13.857 1 13.4444V2.55556C1 2.143 1.16389 1.74733 1.45561 1.45561C1.74733 1.16389 2.143 1 2.55556 1H6.44444'
        stroke={fill}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
