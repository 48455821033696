import { appColors } from '@config/theme';
import { useStore } from '@hooks/storeHook';
import { RedirectIcon } from '@pages/icons/redirectIcon';
import { telegramChatLink } from '@shared/const/tg';
import { Button, ButtonViews } from '@shared/ui/button';
import { observer } from 'mobx-react-lite';

export const RedirectToTgButton = observer(() => {
  const { chatInfo } = useStore();

  const redirectHandler = () => {
    window.open(telegramChatLink + chatInfo?.tg_id, '_blank');
  };

  return (
    <Button
      view={ButtonViews.INLINE}
      icon={
        <RedirectIcon width={14} height={14} fill={appColors['grey-400']} />
      }
      onClick={redirectHandler}
    >
      Чат в TG
    </Button>
  );
});
