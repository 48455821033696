import { useSearchParams } from 'react-router-dom';

declare global {
  interface Window {
    Telegram: any;
  }
}

export function useTelegram() {
  const [searchParams] = useSearchParams();
  return searchParams.get('isBrowser') != 'true';
}

export const darkTheme = window.Telegram?.WebApp?.colorScheme == 'dark';
