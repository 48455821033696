import cn from 'classnames';
import styles from './styles.module.css';
import { ButtonProps } from './types';

export const Button = ({ view, icon, children, ...props }: ButtonProps) => {
  return (
    <button
      className={cn('cursor-pointer flex active:scale-[98%] p-0', styles[view])}
      {...props}
    >
      <div className={styles.icon}>{icon}</div>
      <p className={cn(styles.label, icon && '!border-l')}>{children}</p>
    </button>
  );
};
