import {
  DndContext,
  DragEndEvent,
  DragOverEvent,
  DragOverlay,
  DragStartEvent,
} from '@dnd-kit/core';
import { horizontalListSortingStrategy } from '@dnd-kit/sortable';
import { NewDeskButton } from '@features/newDeskButton';
import { useLogic, useStore } from '@hooks/storeHook';
import { darkTheme, useTelegram } from '@hooks/useTelegram';
import { DragContext, NestableProps } from '@shared/types/dnd.types';
import { DraggableOverlay } from '@shared/ui/draggable/Draggable';
import { SortableItemOverlay } from '@shared/ui/sortable/Sortable';
import { SortableList } from '@shared/ui/sortable/SortableList';
import { CardContent } from '@widgets/card';
import { CardType } from '@widgets/card/types';
import SortableDesk from '@widgets/desk';
import { DeskContent } from '@widgets/desk/components/Desk';
import { Desk } from '@widgets/desk/types';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useCustomSensors } from '../hooks/useCustomSensors';
import { customCollisionDetectionAlgorithm } from '../lib/customCollisionDetectionAlgorithm';

const rootContext = {
  parentId: null,
  indexPath: [],
  data: {},
};

export const Board = observer(() => {
  const { chatInfo, activeDesks, mergedSortedTasks, moveDesk } = useStore();

  const [activeTask, setActiveTask] = useState<DragContext<CardType> | null>(
    null,
  );

  const [activeDesk, setActiveDesk] = useState<DragContext<Desk> | null>(null);

  const initTasks = mergedSortedTasks.map((e) => ({
    id: e.id!,
    type: 'task',
    data: e,
    children: [],
  }));

  useEffect(() => {
    setTasks(
      mergedSortedTasks.map((e) => ({
        id: e.id!,
        type: 'task',
        data: e,
        children: [],
      })),
    );
  }, [mergedSortedTasks]);

  const [tasks, setTasks] = useState<CardType[]>(initTasks);

  const isTg = useTelegram();

  const logic = useLogic();

  const onDragStartHandler = useCallback(
    (event: DragStartEvent) => {
      const active = event.active.data.current as DragContext<NestableProps>;

      if (active?.data.type === 'task') {
        const taskData = active as DragContext<CardType>;
        setActiveTask(taskData);
      } else if (active?.data.type === 'desk') {
        const deskData = active as DragContext<Desk>;
        setActiveDesk(deskData);
      }
    },
    [setActiveTask, setActiveDesk],
  );

  const onDragOverHandler = useCallback(
    (event: DragOverEvent) => {
      const active = event.active.data.current as DragContext<NestableProps>;
      const over = event.over?.data.current as DragContext<NestableProps>;

      if (!over) return;

      const updateTaskDeskId = (newDeskId: number) => {
        setTasks((items): CardType[] =>
          items.map((e) =>
            e.data.id === active.data.id
              ? { ...e, data: { ...e.data, desk_id: newDeskId } }
              : e,
          ),
        );
      };

      if (active.data.type === 'task' && over.data.type === 'desk') {
        const taskData = active as DragContext<CardType>;
        const overData = over as DragContext<Desk>;
        if (taskData?.data.data.desk_id !== overData.data.id) {
          updateTaskDeskId(overData.data.id);
        }
      } else if (active.data.type === 'task' && over.data.type === 'task') {
        const activeData = active as DragContext<CardType>;
        const overData = over as DragContext<CardType>;
        if (activeData?.data.data.desk_id !== overData.data.data.desk_id)
          updateTaskDeskId(overData.data.data.desk_id!);
      } else if (active.data.type === 'desk' && over.data.type === 'desk') {
        const activeData = active as DragContext<Desk>;
        const overData = over as DragContext<CardType>;
        moveDesk(activeData.indexPath[0], overData.indexPath[0]);
      }
    },
    [setTasks],
  );

  const onDragEndHandler = useCallback(
    (event: DragEndEvent) => {
      setActiveTask(null);
      setActiveDesk(null);

      const active = event.active.data.current as DragContext<NestableProps>;
      const over = event.over?.data.current as DragContext<NestableProps>;

      if (!active || !over) return;

      if (active.data.type === 'task' && over.data.type === 'desk') {
        const activeData = active as DragContext<CardType>;
        const overData = over as DragContext<Desk>;
        const targetDeskId =
          chatInfo?.desks.find((item) => item.id === overData.data.id)?.id ?? 0;
        logic.updateBoards(activeData?.data.data, targetDeskId, 0);
      } else if (active.data.type === 'task' && over.data.type === 'task') {
        const activeData = active as DragContext<CardType>;
        const overData = over as DragContext<CardType>;
        logic.updateBoards(
          activeData?.data.data,
          overData.data.data.desk_id!,
          0,
        );
      } else if (active.data.type === 'desk' && over.data.type === 'desk') {
        let query = '';
        activeDesks.forEach((e, i) => {
          query += `&list[${i}][id]=${e.id}`;
          query += `&list[${i}][position]=${e.position}`;
        });
        logic.updateDeskList(query);
      }
    },
    [chatInfo],
  );

  const sensors = useCustomSensors();

  const rootContent = React.useMemo(() => {
    return {
      id: 0,
      type: 'root',
      children: activeDesks.map((e) => ({
        id: e.id!,
        type: 'desk',
        data: e,
        children: [],
      })),
      data: {},
    };
  }, [activeDesks]);

  let activeDrag = null;

  if (activeDesk) {
    activeDrag = (
      <SortableItemOverlay
        ctx={activeDesk}
        className={`${darkTheme ? 'bg-black' : 'bg-boardBackground'} overflow-y-visible select-none touch-none h-fit flex flex-col p-[12px_8px_0] ${isTg ? 'max-w-[80vw] min-w-[80vw] sm:max-w-80 sm:min-w-80' : 'max-w-80 min-w-80'} rounded-normal max-h-[calc(100svh_-_100px)]`}
      >
        <DeskContent
          desk={activeDesk.data}
          isOverlay={true}
          parentId={activeDesk.parentId}
          indexPath={activeDesk.indexPath}
        />
      </SortableItemOverlay>
    );
  } else if (activeTask) {
    activeDrag = (
      <DraggableOverlay
        ctx={activeTask}
        className={`gap-[15px] justify-between rounded-[12px] w-full px-[14px] pt-[7px] pb-[9px] text-[15px] leading-[22px] cursor-pointer ${darkTheme ? 'bg-darkCardBackground' : 'bg-white'}`}
      >
        <CardContent task={activeTask.data} />
      </DraggableOverlay>
    );
  }

  return (
    <section className='h-full'>
      <DndContext
        sensors={sensors}
        collisionDetection={customCollisionDetectionAlgorithm}
        onDragStart={onDragStartHandler}
        onDragOver={onDragOverHandler}
        onDragEnd={onDragEndHandler}
      >
        <SortableList
          accepts={'desk'}
          content={rootContent}
          ctx={rootContext}
          className='flex gap-3 sm:gap-[18px] overflow-x-auto h-full p-[19px_10px] overflow-auto'
          direction={horizontalListSortingStrategy}
        >
          {activeDesks.map((desk, i) => (
            <SortableDesk
              key={desk.id}
              className={`${darkTheme ? 'bg-black' : 'bg-boardBackground'} overflow-y-visible select-none touch-none h-fit flex flex-col p-[12px_8px_0] ${isTg ? 'max-w-[80vw] min-w-[80vw] sm:max-w-80 sm:min-w-80' : 'max-w-80 min-w-80'} rounded-normal max-h-[calc(100svh_-_100px)]`}
              desk={{ id: desk.id, type: 'desk', data: desk, children: tasks }}
              laneIndex={i}
              parentId={null}
            />
          ))}
          {isTg && <NewDeskButton />}
        </SortableList>

        {createPortal(<DragOverlay>{activeDrag}</DragOverlay>, document.body)}
      </DndContext>
    </section>
  );
});
