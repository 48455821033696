import { DeskNameInput } from '@features/deskNameInput';
import { DeskOptionsMenu } from '@features/deskOptionsMenu';
import { useRef } from 'react';
import { DeskHeaderProps } from '../types';

export const DeskHeader = ({ desk }: DeskHeaderProps) => {
  const ref = useRef<HTMLInputElement>(null);

  return (
    <header className='flex items-center select-none'>
      <DeskNameInput desk={desk} ref={ref} />
      <DeskOptionsMenu inputRef={ref} desk={desk} />
    </header>
  );
};
