export const appColors = {
  darkCardBackground: '#323232',
  blue: '#007AFF',
  'blue-secondary': '#DFECF3',
  red: '#FF3B30',
  redButton: '#FFF0F0',
  green: '#30CC3F',
  greenButton: '#EBF9EE',
  black: '#000000',
  'dark-black': '#000000',
  grey: '#C8C7CB',
  greySecondary: '#F1F1F4',
  greySlider: '#E0E0E6',
  greyText: '#807C7C',
  'grey-400': '#525151',
  taskInfoBackground: '#EFEFF4',

  boardBackground: '#EFEFF4',
  subTasksBackground: '#5E5E60',

  orange: '#FF8730',
  'border-grey': '#E5E5EA',
  'grey-text': '#707579',
  'dark-grey': '#AEAEB2',
};

export const getRandomColor = () => {
  const colors = {
    red: appColors.red,
    green: appColors.green,

    orange: appColors.orange,
    blue: appColors.blue,
  };
  // Get all the color names from the appColors object
  const colorNames = Object.keys(colors);

  // Generate a random index between 0 and the length of the colorNames array
  const randomIndex = Math.floor(Math.random() * colorNames.length);

  // Get the random color name from the colorNames array
  const randomColorName = colorNames[randomIndex];

  // Return the random color value from the appColors object
  // @ts-ignore
  return colors[randomColorName];
};
