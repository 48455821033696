import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { areEqualWithCtx } from '@shared/lib/dnd';
import { DragContext, NestableProps } from '@shared/types/dnd.types';
import cn from 'classnames';
import React from 'react';

interface SortableItemProps<T> {
  className?: string;
  ctx: DragContext<T>;
  needAnimate?: boolean;
}

function useClasses({
  isDragging,
  isOverlay,
  className,
}: {
  isDragging: boolean;
  isOverlay: boolean;
  className?: string;
}) {
  return cn(className, 'draggable-item', {
    'opacity-50': isDragging,
    'is-overlay': isOverlay,
  });
}

export const Sortable = React.memo(function SortableItem<
  T extends NestableProps,
>({
  className,
  children,
  ctx,
  needAnimate = true,
}: React.PropsWithChildren<SortableItemProps<T>>) {
  const {
    active,
    attributes,
    isDragging,
    listeners,
    setNodeRef,
    transform,
    transition,
    rect,
  } = useSortable({
    id: ctx.data.id,
    data: ctx,
  });

  const dragRect = rect.current;
  const isTypeSorting =
    transform && active?.data.current?.data.type === ctx.data.type;
  const shouldDisplayDragPlaceholder = isDragging && dragRect;

  const style = React.useMemo(() => {
    let styles: React.CSSProperties | undefined = undefined;

    if (isTypeSorting) {
      styles = needAnimate
        ? {
            transform: CSS.Translate.toString(transform),
            transition: transition || undefined,
          }
        : undefined;
    }

    if (shouldDisplayDragPlaceholder) {
      styles = {
        ...(styles || {}),
        width: dragRect?.width,
        height: dragRect?.height,
      };
    }

    return styles;
  }, [
    isTypeSorting,
    transform,
    transition,
    shouldDisplayDragPlaceholder,
    dragRect,
  ]);

  return (
    <div
      style={style}
      ref={setNodeRef}
      className={useClasses({ isDragging, isOverlay: false, className })}
      {...listeners}
      {...attributes}
    >
      {shouldDisplayDragPlaceholder ? null : children}
    </div>
  );
}, areEqualWithCtx);

export const SortableItemOverlay = React.memo(function SortableItemOverlay<T>({
  className,
  children,
}: React.PropsWithChildren<SortableItemProps<T>>) {
  return (
    <div
      className={useClasses({
        isDragging: false,
        isOverlay: true,
        className,
      })}
    >
      {children}
    </div>
  );
});
