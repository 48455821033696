import { ButtonHTMLAttributes, DetailedHTMLProps, ReactNode } from 'react';

export interface ButtonProps
  extends React.PropsWithChildren<
    DetailedHTMLProps<
      ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    >
  > {
  view: ButtonViews;
  icon?: ReactNode;
}

export enum ButtonViews {
  INLINE = 'inline',
  FILLED = 'filled',
}
