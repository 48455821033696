import { extraLightHapticFeedback } from '@common/telegramHaptic';
import { useLogic, useStore } from '@hooks/storeHook';
import { useTelegram } from '@hooks/useTelegram';
import { PlusIcon } from '@pages/icons/plusIcon';
import { Button, ButtonViews } from '@shared/ui/button';
import { observer } from 'mobx-react-lite';

export const NewDeskButton = observer(() => {
  const isTg = useTelegram();
  const tg = window.Telegram.WebApp;

  const { chatInfo } = useStore();
  const logic = useLogic();

  const onAddBoard = () => {
    extraLightHapticFeedback();

    const name = prompt('Введите название доски');
    const existingDesk = chatInfo?.desks.find(
      (item) => item.name.toLowerCase() === name?.toLowerCase(),
    );
    if (name?.length === 0 || !name || existingDesk) {
      alert('Название не должно повторять существующее или быть пустым');
      tg.HapticFeedback.notificationOccurred('error');
      return;
    }

    logic.createBoard(name);
    extraLightHapticFeedback();
  };

  return isTg ? (
    <button
      onClick={onAddBoard}
      className={`cursor-pointer border-none text-large font-bold text-white bg-green flex justify-center items-center ${isTg ? 'max-w-[80vw] min-w-[80vw] sm:max-w-80 sm:min-w-80' : 'min-w-80 max-w-80'} rounded-normal h-[55px]`}
    >
      Добавить доску
    </button>
  ) : (
    <Button view={ButtonViews.INLINE} icon={<PlusIcon />} onClick={onAddBoard}>
      Доска
    </Button>
  );
});
