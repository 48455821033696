import { useDraggable } from '@dnd-kit/core';
import { areEqualWithCtx } from '@shared/lib/dnd';
import { DragContext, NestableProps } from '@shared/types/dnd.types';
import cn from 'classnames';
import React from 'react';

interface DraggableProps<T> {
  className?: string;
  ctx: DragContext<T>;
}

function useClasses({
  isDragging,
  isOverlay,
  className,
}: {
  isDragging: boolean;
  isOverlay: boolean;
  className?: string;
}) {
  return cn(className, 'draggable-item', {
    'opacity-50': isDragging,
    'is-overlay': isOverlay,
  });
}

export const Draggable = React.memo(function SortableItem<
  T extends NestableProps,
>({ className, children, ctx }: React.PropsWithChildren<DraggableProps<T>>) {
  const { attributes, isDragging, listeners, setNodeRef } = useDraggable({
    id: ctx.data.id,
    data: ctx,
  });

  const shouldDisplayDragPlaceholder = isDragging;

  const style = React.useMemo(() => {
    let styles: React.CSSProperties | undefined = undefined;

    if (shouldDisplayDragPlaceholder) {
      styles = {
        ...(styles || {}),
      };
    }

    return styles;
  }, [shouldDisplayDragPlaceholder]);

  return (
    <div
      style={style}
      ref={setNodeRef}
      className={useClasses({ isDragging, isOverlay: false, className })}
      {...listeners}
      {...attributes}
    >
      {shouldDisplayDragPlaceholder ? null : children}
    </div>
  );
}, areEqualWithCtx);

export const DraggableOverlay = React.memo(function SortableItemOverlay<T>({
  className,
  children,
}: React.PropsWithChildren<DraggableProps<T>>) {
  return (
    <div
      className={useClasses({
        isDragging: false,
        isOverlay: true,
        className,
      })}
    >
      {children}
    </div>
  );
});
