import { NewDeskButton } from '@features/newDeskButton';
import { NewTaskButton } from '@features/newTaskButton';
import { RedirectToTgButton } from '@features/redirectToTgButton';

export const Header = () => {
  return (
    <header className='bg-blue-secondary flex gap-[19px] p-[12px_18px]'>
      <NewDeskButton />
      <NewTaskButton />
      <RedirectToTgButton />
    </header>
  );
};
