import { verticalListSortingStrategy } from '@dnd-kit/sortable';
import { areEqualWithIndexPath } from '@shared/lib/dnd';
import { Sortable } from '@shared/ui/sortable';
import {
  SortableDroppableList,
  SortableListOverlay,
} from '@shared/ui/sortable/SortableList';
import { SortableCard } from '@widgets/card/components/Card';
import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { Desk, DeskProps } from '../types';
import { DeskHeader } from './DeskHeader';

export const DeskContent = React.memo(
  observer(({ desk, isOverlay, parentId, indexPath }: DeskProps) => {
    const ListElement = isOverlay ? SortableListOverlay : SortableDroppableList;

    const filteredTasks = useMemo(() => {
      return desk.children.filter((task) => task.data.desk_id === desk.id);
    }, [desk]);

    const ctx = {
      parentId,
      indexPath,
      data: desk,
    };

    return (
      <>
        <DeskHeader desk={desk.data} />
        <ListElement
          accepts={'task'}
          content={desk}
          ctx={ctx}
          className='overflow-auto'
          direction={verticalListSortingStrategy}
        >
          <main className='overflow-auto max-h-full flex flex-col gap-[10px] pb-[12px] flex-1 mt-4'>
            {filteredTasks.map((task, i) => (
              <SortableCard
                key={task.id}
                isOverlay={!!isOverlay}
                item={task}
                itemIndex={i}
                laneId={desk.id}
                laneIndexPath={indexPath}
              />
            ))}
          </main>
        </ListElement>
      </>
    );
  }),
  areEqualWithIndexPath,
);

export const SortableDesk = React.memo(function SortableDesk({
  desk,
  parentId,
  laneIndex,
  className,
}: {
  desk: Desk;
  parentId: number | null;
  laneIndex: number;
  className: string;
}) {
  const ctx = {
    parentId,
    indexPath: [laneIndex],
    data: desk,
  };

  return (
    <Sortable className={className} ctx={ctx}>
      <DeskContent parentId={parentId} indexPath={ctx.indexPath} desk={desk} />
    </Sortable>
  );
});
