import { DeskTypes } from '@@types/types';
import { extraLightHapticFeedback } from '@common/telegramHaptic';
import { useLogic, useStore } from '@hooks/storeHook';
import { useTelegram } from '@hooks/useTelegram';
import { DatePickerDrawer } from '@pages/TaskPage/DatePickerDrawer';
import { DateCircle } from '@ui/DateCircle/DateCircle';
import { DateSelector } from '@ui/EntitySelectors/DateSelector/DateSelector';
import { MiniModal } from '@ui/MiniModal/MiniModal';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { useRef, useState } from 'react';
import { PickerProps } from '../types';

export const Picker = observer(({ task }: PickerProps) => {
  const [parentElement, setParentElement] = useState<Element | null>(null);
  const ref = useRef<HTMLDivElement>(null);
  const { chatInfo, mergedSortedTasks } = useStore();
  const isTg = useTelegram();
  const [isSelectorOpen, setIsSelectorOpen] = useState(false);
  const logic = useLogic();

  const onSaveDateHandler = async (date: Date | null, priority?: true) => {
    logic.updateTask({
      ...mergedSortedTasks.find((e) => e.id === task.id)!,
      deadline: date ? moment(date).format('YYYY-MM-DD') : null,
      priority: priority ? 1 : 0,
    });
    extraLightHapticFeedback();
    setIsSelectorOpen(false);
  };

  const onClickHandler = () => {
    setParentElement(ref.current);
    setIsSelectorOpen(true);
  };

  const onCloseSelectorHandler = () => {
    setIsSelectorOpen(false);
  };

  return (
    <>
      {chatInfo?.desks.find(
        (e) => e.id === task.desk_id && e.type !== DeskTypes['DONE'],
      ) && (
        <div ref={ref} className='inline-flex align-top'>
          <DateCircle task={task} onClick={onClickHandler} />
        </div>
      )}
      {parentElement && isSelectorOpen && !isTg && (
        <MiniModal
          parent={parentElement}
          direction='right'
          headerTitle='Дата выполнения'
          onHide={onCloseSelectorHandler}
          body={<DateSelector task={task} onSave={onSaveDateHandler} />}
          classname='w-[390px]'
        />
      )}
      {isTg && (
        <DatePickerDrawer
          isVisible={isSelectorOpen}
          task={task}
          onSaveDateHandler={onSaveDateHandler}
          onCloseDrawer={onCloseSelectorHandler}
        />
      )}
    </>
  );
});
