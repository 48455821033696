/* eslint-disable @typescript-eslint/no-unused-vars */
import { DeskTypes, ITask } from '@@types/types';
import { extraLightHapticFeedback } from '@common/telegramHaptic';
import { AppRoutesPaths } from '@config/navigation';
import { appColors } from '@config/theme';
import { useLogic, useStore } from '@hooks/storeHook';
import { useTelegram } from '@hooks/useTelegram';
import { CompleteIcon } from '@pages/icons/completeIcon';
import { DeleteIcon } from '@pages/icons/deleteIcon';
import { goBack } from '@shared/lib/nav';
import { Button } from '@ui/Button/Button';
import { Switcher } from '@ui/Switcher/Switcher';
import { ITaskModal } from '@ui/TaskModal/TaskModal';
import { motion } from 'framer-motion';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { AssignedSelectButton } from './AssignedSelectButton';
import { DateSelectButton } from './DateSelectButton';
import { FileMenu } from './FileMenu';
import { SelectBoardButton } from './SelectBoardButton';
import { SettingSection } from './SettingSection';
import { TaskAndSubtasksComponent } from './TaskAndSubTasksComponent';
import { CommentsFilesMenu } from './components/CommentsFilesMenu';

const sliderElements = ['Задачи', 'Файлы'];

interface ITaskPage extends ITaskModal {}

export const TaskPage: FC<ITaskPage> = observer(({ onHide }) => {
  const isTg = useTelegram();

  const logic = useLogic();
  const location = useLocation();

  const {
    selectedTask,
    chatInfo,
    activeDesks: activeDesks,
    resetSelectedTask,
  } = useStore();

  const [taskInfo, setTaskInfo] = useState(selectedTask);
  const [activeIndex, setActiveIndex] = useState(0);

  if (!selectedTask || !taskInfo) {
    return null;
  }

  useEffect(() => () => resetSelectedTask(), []);

  const tg = window.Telegram?.WebApp;

  useEffect(() => {
    if (!isTg) return;

    tg.MainButton.text = 'СОХРАНИТЬ';
    tg.MainButton.color = appColors.blue;

    const timeout = setTimeout(() => {
      tg.MainButton.show();
      tg.MainButton.onClick(onSave);
    }, 200);

    return () => {
      tg.MainButton.offClick(onSave);
      clearTimeout(timeout);
    };
  }, [taskInfo]);

  useEffect(() => {
    if (!isTg) return;

    extraLightHapticFeedback();
    if (activeIndex === 0) tg.MainButton.show();
    else tg.MainButton.hide();
  }, [activeIndex]);

  useEffect(() => {
    if (!isTg) return;

    tg.isClosingConfirmationEnabled = true;

    tg.BackButton.show();
    tg.setHeaderColor('#efeff4');

    tg.BackButton.offClick(goBack);
    tg.BackButton.onClick(onHide);

    return () => {
      tg.isClosingConfirmationEnabled = false;

      tg.BackButton.onClick(goBack);

      if (location.pathname === AppRoutesPaths.AllTasksAndChats)
        tg.BackButton.hide();

      tg.BackButton.offClick(onHide);
      if (tg.colorScheme === 'dark') {
        tg.setHeaderColor('#2C2C2E');
      } else {
        tg.setHeaderColor('#F9F9F9');
      }
    };
  }, []);

  const setTask = (field: string, value: ITask[keyof ITask]) => {
    const newObj = { ...taskInfo };
    if (field in taskInfo) {
      // @ts-ignore
      newObj[field] = value;
    }
    setTaskInfo(newObj);
  };

  //ПЕРЕДЕЛАТЬ! КОСТЫЛЬ ДЛЯ ВЫБОРА ДАТЫ (функция setTask не может сразу обновить 2 поля)
  const setTaskForDate = (value: ITask) => {
    setTaskInfo(value);
  };

  const onSave = async () => {
    tg.MainButton.showProgress();

    if (taskInfo.id) await logic.updateTask(taskInfo);
    else {
      const formData = new FormData();
      formData.append('title', taskInfo.title ?? '');
      taskInfo.deadline && formData.append('deadline', taskInfo.deadline!);
      formData.append('desk_id', String(taskInfo.desk!.id));
      taskInfo.responsible[0] &&
        formData.append(
          'responsible[0][id]',
          String(taskInfo.responsible[0].id),
        );
      taskInfo.subtasks.forEach((e, i) => {
        formData.append(`subtasks[${i}][title]`, e.title);
        formData.append(`subtasks[${i}][status]`, e.status);
      });
      taskInfo.newFiles!.forEach((e, i) => {
        formData.append(`files[${i}]`, e.blob);
      });
      await logic.createTask(formData);
    }

    tg.HapticFeedback.notificationOccurred('success');
    tg.MainButton.hideProgress();
    tg.MainButton.hide();
    onHide();
  };

  const onUpdateTaskStatus = async () => {
    const completeDesk = chatInfo?.desks.find(
      (item) => item.type === DeskTypes['DONE'],
    );
    if (completeDesk) {
      await logic.updateTask({ ...selectedTask, desk: completeDesk });
      extraLightHapticFeedback();

      onHide();
    }
  };

  const onDeleteTask = async () => {
    await logic.deleteTask(taskInfo.id!);
    extraLightHapticFeedback();
    onHide();
  };

  return (
    <div
      className={`w-full h-screen flex  ${isTg ? 'bg-taskInfoBackground' : 'bg-white overflow-y-hidden'}  gap-[25px]`}
    >
      <div
        className={`${isTg ? 'w-full' : 'min-w-[356px]'} max-h-screen overflow-y-hidden overflow-x-hidden flex flex-col gap-[25px] pb-[15px] h-full relative`}
      >
        {isTg && (
          <div className='fixed top-0 left-0 right-0 w-full z-[2] p-[16px] backdrop-blur-sm'>
            <Switcher
              elements={sliderElements}
              onClick={(index) => setActiveIndex(index)}
              activeIndex={activeIndex}
            />
          </div>
        )}

        <motion.div
          className='w-full h-full flex gap-[20px] transition-transform duration-200 ease-in-out'
          style={{
            translateX: activeIndex === 0 ? '0%' : 'calc(-100% - 20px)',
          }}
        >
          <section
            className={`min-w-full h-full flex flex-col gap-[25px] ${isTg ? 'pt-[64px]' : 'pt-6'} overflow-y-auto  px-[15px]`}
          >
            <SettingSection name='Задача и подзадачи'>
              <TaskAndSubtasksComponent setTask={setTask} task={taskInfo} />
            </SettingSection>

            <SettingSection name='Настройки'>
              <DateSelectButton task={taskInfo} setTask={setTaskForDate} />
              <AssignedSelectButton
                users={chatInfo?.users}
                task={taskInfo}
                setTask={setTask}
              />
              {chatInfo?.desks && (
                <SelectBoardButton
                  task={taskInfo}
                  setTask={setTask}
                  desks={activeDesks}
                />
              )}
            </SettingSection>

            {taskInfo.id && (
              <SettingSection name='Действия'>
                <div
                  onClick={onUpdateTaskStatus}
                  className='flex gap-[16px] items-center w-full cursor-pointer'
                >
                  <CompleteIcon />
                  <div className='flex items-center w-full h-[46px] border-solid  border-0 border-border-grey border-b-[1px]'>
                    <span className='text-large text-green'>
                      Выполнить задачу
                    </span>
                  </div>
                </div>
                <div
                  onClick={onDeleteTask}
                  className='flex gap-[16px] items-center w-full cursor-pointer'
                >
                  <DeleteIcon />
                  <div className='flex  items-center w-full h-[46px]  '>
                    <span className='text-large text-red'>Удалить</span>
                  </div>
                </div>
              </SettingSection>
            )}

            {!isTg && (
              <Button classname='mx-[18px] mt-auto' onClick={onSave}>
                Сохранить
              </Button>
            )}
          </section>

          <section className='min-w-full h-full flex flex-col gap-[25px] pt-[64px] px-[15px]'>
            <FileMenu setTask={setTask} task={taskInfo} />
          </section>
        </motion.div>
      </div>
      {!isTg && <CommentsFilesMenu setTask={setTask} task={taskInfo} />}
    </div>
  );
});
