import { CustomComparator, MapAny } from '@common/tsUtils';
import { areArraysEqual, is } from './common';

function shallowEqual(
  objA: MapAny,
  objB: MapAny,
  customCompare: CustomComparator = () => false,
): boolean {
  if (is(objA, objB)) {
    return true;
  }

  if (
    typeof objA !== 'object' ||
    objA === null ||
    typeof objB !== 'object' ||
    objB === null
  ) {
    return false;
  }

  const keysA = Object.keys(objA);
  const keysB = Object.keys(objB);

  if (keysA.length !== keysB.length) {
    return false;
  }

  // Test for A's keys different from B.
  for (let i = 0; i < keysA.length; i++) {
    if (!Object.prototype.hasOwnProperty.call(objB, keysA[i])) {
      return false;
    }
    if (
      !is(objA[keysA[i]], objB[keysA[i]]) &&
      !customCompare(keysA[i], objA[keysA[i]], objB[keysA[i]])
    ) {
      return false;
    }
  }

  return true;
}

export function areEqualWithIndexPath(objA: MapAny, objB: MapAny) {
  return shallowEqual(objA, objB, (k, a, b) => {
    if (k === 'indexPath') {
      return areArraysEqual(a, b);
    }

    return false;
  });
}

export function areEqualWithCtx(objA: MapAny, objB: MapAny) {
  return shallowEqual(objA, objB, (k, a, b) => {
    if (k === 'ctx') {
      return areEqualWithIndexPath(a, b);
    }

    return false;
  });
}
