import { useStore } from '@hooks/storeHook';
import { useTelegram } from '@hooks/useTelegram';
import { PlusIcon } from '@pages/icons/plusIcon';
import { Button, ButtonViews } from '@shared/ui/button';
import { Icon } from './Icon';

export const NewTaskButton = () => {
  const isTg = useTelegram();
  const { setEmptyTask } = useStore();

  const onAddTaskHandler = () => {
    setEmptyTask();
  };

  return isTg ? (
    <button
      onClick={onAddTaskHandler}
      className='absolute bottom-[35px] right-[35px] bg-transparent border-none cursor-pointer'
    >
      <Icon />
    </button>
  ) : (
    <Button
      view={ButtonViews.INLINE}
      icon={<PlusIcon />}
      onClick={onAddTaskHandler}
    >
      Задача
    </Button>
  );
};
