export const Icon = () => (
  <svg
    width='70'
    height='70'
    viewBox='0 0 70 70'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle cx='35' cy='35' r='35' fill='#2AB65A' />
    <path
      d='M32.6242 49V21H37.3758V49H32.6242ZM21 37.3758V32.6242H49V37.3758H21Z'
      fill='white'
    />
  </svg>
);
